import React, { useState, useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import OfficeAddress from './addreses/officeaddress';
import ProdAddress from './addreses/prodaddress';
import ServiceAddress from './addreses/serviceaddress';
import AutoWay from './addreses/autoway';
import TransportWay from './addreses/transportway';

import OfficeGalleryContact from "../ContactGallery/OfficeGalleryContacts"
import ProdGalleryContact  from '../ContactGallery/ProdGalleryContact';
import ServiceGalleryContact from '../ContactGallery/ServiceGalleryContact';


function ContactInfo() {
  const [address, setAddress] = useState(OfficeAddress);
  const [gallery, setGallery] = useState(<OfficeGalleryContact />);
  

  useEffect(() => {

  });

  const setOfficeData = () => {
    setAddress(OfficeAddress);
    setGallery(<OfficeGalleryContact />)
  }

  const setProdData = () => {
    setAddress(ProdAddress);
    setGallery(<ProdGalleryContact />)
  }

  const setServiceData = () => {
    setAddress(ServiceAddress);
    setGallery(<ServiceGalleryContact />)
  }


  return (
        <Container fluid className="info-block-container">
          <Row style={{height: 0}}>
            <Col xl={5} lg={12} className="info-text-block">
              <div>
                <Container>
                  <Row className="dep-buttons">
                    <Col>
                      <button className="contactitem-button" onClick={setOfficeData}>офис</button> 
                    </Col>
                    <Col>
                      <button className="contactitem-button" onClick={setProdData}>производство</button> 
                    </Col>
                    <Col>
                      <button className="contactitem-button" onClick={setServiceData}>сервисная служба</button>
                    </Col>
                  </Row>
                  <Row className="info-block-text">
                    <Col xl={12}>
                      <div class="ref-contact-infoblock-address-info">{address}</div>
                    </Col>
                  </Row>
                  <Row className="transport-button">
                    <Col>
                      <button className="contactitem-button"  style={{cursor: 'pointer'}} onClick={()=> setAddress(AutoWay)}>на автомобиле</button>
                    </Col>
                    <Col>
                      <button className="contactitem-button" style={{cursor: 'pointer'}} onClick={()=> setAddress(TransportWay)}>на общественном транспорте</button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>

            <Col xl={2} style={{height: '0'}}></Col>

            <Col xl={5} style={{height: '0'}}>
              {gallery}
            </Col>

          </Row>
        </Container>
  );
}

export default ContactInfo;