import React from "react";


const AutoWay = () => {

  return (
      <div className="contactaddress">
        <div className="addressblock">
          <span className="contact-label" >добраться до офиса на автомобиле:</span>
          <p style={{fontWeight: '400'}}><br />
            — Ленинградское шоссе из Москвы в область 5,5 км от МКАД, <br />поворот направо на Вашутинское шоссе, <br />через 1,5 км находится здание «BOSСH».</p>
        </div>
      </div>
)
}

export default AutoWay;