import React from "react"
// import OfficeImages from '../galleries/officegallery';


const OfficeAddress = () => {

  return (
    <div>
      <div className="contactaddress">
        <div className="addressblock">
          <span className="contact-label" >телефон:</span>
          <p>+7 (495) 72-101-11</p>
          <span className="contact-label" >e-mail:</span>
          <p><a href="mailto:info@refeng.ru?subject=Переход по ссылке адреса e-mail">info@refeng.ru</a></p>
          <span className="contact-label" >Пн-Пт с 9-00 до 20-00</span>
          <br/><br/>
          <span className="contact-label" >адрес:</span>
          <p style={{fontWeight: '400'}}>141402, г.Химки, Вашутинское шоссе, д.24, <br/>здание Bosch, офис 325С</p>
          <br/>
          <span className="contact-label" >почтовый адрес:</span>
          <p style={{fontWeight: '400'}}>141402, а/я 441, г.Химки, МО</p>
        </div>
        
      </div>
      {/* <div style={{position: 'absolute', height: '150px', width: '800px', margin: '1.8em 47em'}}>
        <OfficeImages />
      </div> */}
    </div>
)
}

export default OfficeAddress;