import React from "react"
// import ProdImages from '../galleries/prodgallery';


const ProdAddress = () => {

  return (
    <div>
      <div className="contactaddress">
      <div className="addressblock">
          {/* <p>Производственный цех находится рядом с<br/> офисным зданием.</p> */}
          <br/>
          <span className="contact-label" >адрес:</span>
          <p style={{fontWeight: '400'}}>Московская обл., Клинский район, г. Клин, <br/>Волоколамское шоссе, д.4</p>
        </div>
      </div>
      {/* <div style={{position: 'absolute', height: '150px', width: '800px', margin: '2.621em 47em'}}>
      <ProdImages />
      </div> */}
    </div>
)
}

export default ProdAddress;