import React from 'react';
import ContactsInfoBlock from "./ContactsInfoBlock"




const ContactsInfo = () => {
    return(

        <ContactsInfoBlock />
    )
}

export default ContactsInfo