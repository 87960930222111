import React from "react";
// import ServiceImages from '../galleries/servicegallery';



const ServiceAddress = () => {

  return (
    <div>
      <div className="contactaddress">
      <div className="addressblock">
          <span className="contact-label" >телефоны:</span>
          <p>+7 (495) 72-144-77</p>
          <p>+7 (495) 72-999-27</p>
          <span className="contact-label" >Сервисная служба 24 часа.</span>
          <br/><br/>
          <span className="contact-label" >адрес склада:</span>
          <p style={{fontWeight: '400'}}>Московская обл., Клинский район, г. Клин, <br/>Волоколамское шоссе, д.4</p>
        </div>
      </div>
      {/* <div style={{position: 'absolute', height: '150px', width: '800px', margin: '0.975em 47em'}}>
       <ServiceImages />
      </div> */}
    </div>
)
}

export default ServiceAddress;