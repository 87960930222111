import React from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps';


const mapState = { center: [55.921231, 37.412440], zoom: 16, behaviors: ['drag', "disable('scrollZoom')"] };
// const coordinates = [
//   [55.74689689342914, 37.60188872176091],
//   [55.76754150393748, 49.20624545413351]
// ];

// , position: 'absolute', bottom: '0'          
// ref.behaviors.disable('scrollZoom');
// GeolocationControl, FullscreenControl


// style={{width: '100%', height: '1100px'}}

const MoscowMap = () => (
  <>
  <YMaps>
    <Map state={mapState} className="yandex-map" modules={["package.full"]}
      instanceRef={ref => {
            if (ref) {
              ref.events.add("click", e => {
                ref.balloon.close();
              });
            }
            // ref.behaviors.enable('drag');
          }}
        
    >
    <div className="contacts-info-block">
        <h1>КОНТАКТЫ</h1>
        
    </div>


{/* <FullscreenControl /> */}
{/* <GeolocationControl /> */}

<Placemark
            modules={["geoObject.addon.balloon"]}
            geometry={[55.921259, 37.413924]}
            properties={{
                iconCaption: 'Офис "Рефинжиниринг"',
                balloonContent: "Мы работаем: <p><strong>Пн-Пт:</strong>с 9:00 до 20:00</p><p><strong>Сб-Вс:</strong>выходные дни</p>"
            }}
          />

    {/* {coordinates.map(coordinate => <Placemark geometry={coordinate} properties={{iconContent: 'Офис "Рефинжиниринг'}} />)} */}

    </Map>
  </YMaps>
  </>
);

export default MoscowMap