import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import TopDark from "../Modules/Top/TopDarkRelative";
import YandexMap from "../Modules/YandexMap";
import ContactFormBlock from "./ContactFormBlock";
import Footer from "../Modules/FooterPage"


import InfoBlock from "../../components/Modules/ContactsInfo"
import InfoBlockAdaptive from "../../components/Modules/ContactsInfo/ContactsInfoBlockAdaptive"


const ContactPage = () => {
    return(
        <Container fluid>
            <Row>
                <Col>
                <TopDark />
                </Col>
            </Row>
            <Row style={{alignItems: 'flex-start'}}>
                <Col xl={12} style={{position: 'absolute', zIndex: '9', top: '28vh'}}>
                    <InfoBlock />
                </Col>
                <Col xl={12} style={{padding: '0'}}>
                    <YandexMap />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InfoBlockAdaptive />
                </Col>
            </Row>
            <Row>
                <Col style={{padding: '0'}}>
                    <ContactFormBlock />
                </Col>
            </Row>
            <Row>
                <Col style={{padding: '0'}}>
                    <Footer />
                </Col>
            </Row>

        </Container>
    )
}

export default ContactPage