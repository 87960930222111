import React from "react"



const TransportWay = () => {

  return (
    
      <div className="contactaddress">
        <div className="addressblock">
          <span className="contact-label" >добраться до офиса на общественном транспорте:</span>
          <p style={{fontWeight: '400'}} ><br />
            — от ст. метро «Речной вокзал» автобусом №199, №451, №851 <br />до ост. «Беломорская улица»,<br /> 
            далее на маршрутном такси №342к (Клязьма) <br />до остановки перед зданием «BOSСH».<br /><br />
            — от ст. метро «Планерная» маршрутное такси №946к до <br />«МЕГА-ИКЕА», далее на маршрутных такси №16к или №20к <br />
            до остановки перед зданием «BOSСH».<br /><br />
            — от ст. метро «Ховрино» автобусом №345 в направлении <br />«Станция Химки», до остановки "Химкинская больница",<br /> 
            далее на маршрутном такси  №342к (Клязьма)<br /> до остановки перед зданием «BOSСH».
            </p>
        </div>
      </div>
)
}

export default TransportWay;