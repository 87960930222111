import React, { useState } from "react";
import Slider from "react-slick";

import CloseBtn from "../../../images/contactpage/close.png"


const PREFIX_URL = 'https://raw.githubusercontent.com/surreum/imagestore/main/refeng/officegallery';

export default function OfficeGalleryContact() {

    const [slidesHide, setSlidesActive] = useState("contacts-gallery")

    const handleToggle = () => {
        const slideList = document.querySelector('.contacts-gallery .slick-list ');
        // console.log(slideList);
        // setSlidesActive("contacts-gallery active");
        slideList.style.left = '0';
      };

    const hideSlides = () => {
        const slideList = document.querySelector('.contacts-gallery .slick-list');
        setSlidesActive("contacts-gallery");
        slideList.style.left = '-10000px';
    };

    
    const settings = {
      customPaging: function(i) {
        return (
          <button>
            <img src={`${PREFIX_URL}/office-0${i + 1}.jpg`} role="presentation" onClick = {handleToggle} onKeyDown = {handleToggle} alt="office gallery thumbnail"/>
          </button>
        );
      },
      dots: true,
      dotsClass: "slick-dots-ref",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className={slidesHide}>
          
        <Slider {...settings} className="refslider-body">
          <div>
            <button className="info-close-button" style={{position: "absolute", zIndex: "9", color: "#fff"}} onClick={hideSlides} aria-label="Close on click"><img src={CloseBtn} alt="" /></button>
            <img src={PREFIX_URL + "/office-01.jpg"}  style={{width: '100%'}} alt="Офис компании Рефинжиниринг" />
          </div>
          <div>
            <button className="info-close-button" style={{position: "absolute", zIndex: "9", color: "#fff"}} onClick={hideSlides} aria-label="Close on click"><img src={CloseBtn} alt="" /></button>
            <img src={PREFIX_URL + "/office-02.jpg"} style={{width: '100%'}} alt="Офис компании Рефинжиниринг" />
          </div>
          <div>
            <button className="info-close-button" style={{position: "absolute", zIndex: "9", color: "#fff"}} onClick={hideSlides} aria-label="Close on click"><img src={CloseBtn} alt="" /></button>
            <img src={PREFIX_URL + "/office-03.jpg"} style={{width: '100%'}} alt="Офис компании Рефинжиниринг" />
          </div>
          <div>
            <button className="info-close-button" style={{position: "absolute", zIndex: "9", color: "#fff"}} onClick={hideSlides} aria-label="Close on click"><img src={CloseBtn} alt="" /></button>
            <img src={PREFIX_URL + "/office-04.jpg"} style={{width: '100%'}} alt="Офис компании Рефинжиниринг" />
          </div>
        </Slider>
      </div>
    );
  }

