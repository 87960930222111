import React from 'react'
import MoscowMap from './MoscowMap'

const ContactMoscow = () => {
    return (
        <>
            <MoscowMap />
        </>
    )
}

export default ContactMoscow