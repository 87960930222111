import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import ContactFormMode from '../Modules/ContactForms/ContactsForm/ContactFormMode';


const ContactPage = () => {
    return(
        <Container fluid className="contact-form-container">
            <Row>
                <Col xl={3}>
                    <h2>СВЯЖИТЕСЬ С НАМИ</h2>
                    <p>Заполните форму.</p><p>Наш специалист свяжется с </p><p>вами в ближайшее время</p>
                </Col>
                <Col xl={2}></Col>
                <Col xl={7}>
                    <ContactFormMode />
                </Col>
            </Row>
        </Container>
    )
}

export default ContactPage